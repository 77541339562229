<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
      "Go to store": "Ir para a loja virtual",
      Menu: "Menu",
      Dashboard: "Painel",
      Register: "Cadastrar",
      Courses: "Cursos",
      Store: "Loja",
      Products: "Produtos",
      Cart: "Carrinho",
      "My Orders": "Meus Pedidos",
      Wallet: "Carteira",
      Extract: "Extrato",
      Network: "Rede",
      Profit: "Rendimento",
      Activation: "Ativação",
      Balance: "Saldo",
      Transfer: "Transferir",
      Pay: "Pagar",
      Withdrawal: "Sacar",
      "Withdrawal History": "Histórico de Saque",
      Sponsored: "Indicados",
      Team: "Equipe",
      Binary: "Binário",
      Reports: "Relatórios",
      "Bonus Referral": "Bônus Indicação",
      "Bonus Team": "Bônus Equipe",
      "Bonus Shopping": "Bônus Compras",
      Graduation: "Graduação",
      Support: "Suporte",
      Downloads: "Downloads",
      FAQ: "FAQ",
      Tickets: "Tickets",
      Points: "Pontos",
      "Monthly Activation": "Ativação Mensal",
    },
    es: {
      "Go to store": "Ir a la tienda virtual",
      Menu: "Menu",
      Dashboard: "Panel",
      Register: "Registro",
      Courses: "Cursos",
      Store: "Tienda",
      Products: "Productos",
      Cart: "Cesta",
      "My Orders": "Mis Pedidos",
      Wallet: "Cartera",
      Extract: "Extracto",
      Network: "Red",
      Profit: "Rendimiento",
      Activation: "Activación",
      Balance: "Saldo",
      Transfer: "Transferir",
      Pay: "Pagar",
      Withdrawal: "Retirada",
      "Withdrawal History": "Historia de Retirada",
      Sponsored: "Patrocinados",
      Team: "Equipo",
      Binary: "Binario",
      Reports: "Informes",
      "Bonus Referral": "Bonus Patrocinio",
      "Bonus Team": "Bônus Equipo",
      "Bonus Shopping": "Bônus Compras",
      Graduation: "Graduación",
      Support: "Soporte",
      Downloads: "Descargas",
      FAQ: "FAQ",
      Tickets: "Tickets",
      Points: "Puntos",
      "Monthly Activation": "Activación Mensual",
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  mounted: function () {
    document.body.setAttribute("data-sidebar", "light");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }
  },
  methods: {
  },
};
</script>

<template>
  <div id="sidebar-menu" class="pt-3">
    <ul id="side-menu" class="metismenu list-unstyled">
      <li class="bg-soft-warning rounded-lg mb-2">
        <router-link tag="a" to="/store/featured" class="side-nav-link-ref border-0 mt-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
            <path d="M21.822 7.431A1 1 0 0 0 21 7H7.333L6.179 4.23A1.994 1.994 0 0 0 4.333 3H2v2h2.333l4.744 11.385A1 1 0 0 0 10 17h8c.417 0 .79-.259.937-.648l3-8a1 1 0 0 0-.115-.921z"></path>
            <circle cx="10.5" cy="19.5" r="1.5"></circle>
            <circle cx="17.5" cy="19.5" r="1.5"></circle>
          </svg>
          <span class="notranslate">{{ t("Loja Virtual") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
          <span class="notranslate">{{ t("Dashboard") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/experts" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
            <path
              d="m6.516 14.323-1.49 6.452a.998.998 0 0 0 1.529 1.057L12 18.202l5.445 3.63a1.001 1.001 0 0 0 1.517-1.106l-1.829-6.4 4.536-4.082a1 1 0 0 0-.59-1.74l-5.701-.454-2.467-5.461a.998.998 0 0 0-1.822 0L8.622 8.05l-5.701.453a1 1 0 0 0-.619 1.713l4.214 4.107zm2.853-4.326a.998.998 0 0 0 .832-.586L12 5.43l1.799 3.981a.998.998 0 0 0 .832.586l3.972.315-3.271 2.944c-.284.256-.397.65-.293 1.018l1.253 4.385-3.736-2.491a.995.995 0 0 0-1.109 0l-3.904 2.603 1.05-4.546a1 1 0 0 0-.276-.94l-3.038-2.962 4.09-.326z">
            </path>
          </svg>
          <span class="notranslate">Especialistas</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/events" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
            <path d="M20 3H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h7v3H8v2h8v-2h-3v-3h7c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 15V5h16l.001 10H4z"></path>
            <path d="m10 13 5-3-5-3z"></path>
          </svg>
          <span class="notranslate">Multiverso Show</span>
        </router-link>
      </li>
      <li class="d-none">
        <router-link tag="a" to="/plans" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
            <path
              d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zM4 12c0-.899.156-1.762.431-2.569L6 11l2 2v2l2 2 1 1v1.931C7.061 19.436 4 16.072 4 12zm14.33 4.873C17.677 16.347 16.687 16 16 16v-1a2 2 0 0 0-2-2h-4v-3a2 2 0 0 0 2-2V7h1a2 2 0 0 0 2-2v-.411C17.928 5.778 20 8.65 20 12a7.947 7.947 0 0 1-1.67 4.873z">
            </path>
          </svg>
          <span class="notranslate">{{ t("Meu Plano") }}</span>
        </router-link>
      </li>
      <li class="d-none">
        <router-link tag="a" to="/courses" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
            <path d="M2 7v1l11 4 9-4V7L11 4z"></path>
            <path d="M4 11v4.267c0 1.621 4.001 3.893 9 3.734 4-.126 6.586-1.972 7-3.467.024-.089.037-.178.037-.268V11L13 14l-5-1.667v3.213l-1-.364V12l-3-1z"></path>
          </svg>
          <span class="notranslate">{{ t("Courses") }}</span>
        </router-link>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
            <path d="M12 10c1.151 0 2-.848 2-2s-.849-2-2-2c-1.15 0-2 .848-2 2s.85 2 2 2zm0 1c-2.209 0-4 1.612-4 3.6v.386h8V14.6c0-1.988-1.791-3.6-4-3.6z"></path>
            <path d="M19 2H5c-1.103 0-2 .897-2 2v13c0 1.103.897 2 2 2h4l3 3 3-3h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm-5 15-2 2-2-2H5V4h14l.002 13H14z"></path>
          </svg>
          <span class="notranslate">Rede</span>
        </a>
        <ul class="sub-menu">
          <li>
            <router-link tag="a" to="/network/sponsored" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Sponsored") }}</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/network/team" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Team") }}</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/network/matriz" class="side-nav-link-ref">
              <span class="notranslate">{{ t("Matriz") }}</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <a class="is-parent has-arrow" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
            <path d="M5 21h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM5 5h14l.001 14H5V5z"></path>
            <path d="m13.553 11.658-4-2-2.448 4.895 1.79.894 1.552-3.105 4 2 2.448-4.895-1.79-.894z"></path>
          </svg>
          <span class="notranslate">Relatórios</span>
        </a>
        <ul class="sub-menu">
          <li class="d-none">
            <router-link tag="a" to="/reports/experts" class="side-nav-link-ref">
              <span class="notranslate">Especialista</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/reports/indicacao" class="side-nav-link-ref">
              <span class="notranslate">Indicação</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/reports/compras" class="side-nav-link-ref">
              <span class="notranslate">Compras</span>
            </router-link>
          </li>
          <li>
            <router-link tag="a" to="/reports/graduation" class="side-nav-link-ref">
              <span class="notranslate">Graduação</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link tag="a" to="/wallet/extract" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
            <path d="M16 12h2v4h-2z"></path>
            <path
              d="M20 7V5c0-1.103-.897-2-2-2H5C3.346 3 2 4.346 2 6v12c0 2.201 1.794 3 3 3h15c1.103 0 2-.897 2-2V9c0-1.103-.897-2-2-2zM5 5h13v2H5a1.001 1.001 0 0 1 0-2zm15 14H5.012C4.55 18.988 4 18.805 4 18V8.815c.314.113.647.185 1 .185h15v10z">
            </path>
          </svg>
          <span class="notranslate">{{ t("Financeiro") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/downloads" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
            <path d="m12 16 4-5h-3V4h-2v7H8z"></path>
            <path d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z"></path>
          </svg>
          <span class="notranslate">{{ t("Downloads") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/faq" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
            <path
              d="M12 4C9.243 4 7 6.243 7 9h2c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.069-.454 1.465-1.481 2.255-.382.294-.813.626-1.226 1.038C10.981 13.604 10.995 14.897 11 15v2h2v-2.009c0-.024.023-.601.707-1.284.32-.32.682-.598 1.031-.867C15.798 12.024 17 11.1 17 9c0-2.757-2.243-5-5-5zm-1 14h2v2h-2z">
            </path>
          </svg>
          <span class="notranslate">{{ t("FAQ") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/tickets" class="side-nav-link-ref">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: currentColor;">
            <path
              d="M12 2C6.486 2 2 6.486 2 12v4.143C2 17.167 2.897 18 4 18h1a1 1 0 0 0 1-1v-5.143a1 1 0 0 0-1-1h-.908C4.648 6.987 7.978 4 12 4s7.352 2.987 7.908 6.857H19a1 1 0 0 0-1 1V18c0 1.103-.897 2-2 2h-2v-1h-4v3h6c2.206 0 4-1.794 4-4 1.103 0 2-.833 2-1.857V12c0-5.514-4.486-10-10-10z">
            </path>
          </svg>
          <span class="notranslate">{{ t("Tickets") }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.py-2 {
  padding: 0.9rem 1.5rem 0.7rem !important;
}

.py-2 i,
.py-2 span {
  line-height: 0px !important;
  padding-bottom: 0 !important;
}

.py-2 i {
  margin-top: -4px;
}
</style>